/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)

const $ = require('jquery');

import 'select2';
import 'select2/dist/css/select2.css';

import './styles/app.scss';

//We are using alpine2, not 3...
//import 'alpinejs';

import './alpinejs';

console.log('ALPINE???');

const axios = require('axios');

// start the Stimulus application
import './bootstrap';

$(document).ready(function() {
    $('.select2').select2({});
//https://stackoverflow.com/questions/25882999/set-focus-to-search-text-field-when-we-click-on-select-2-drop-down
 $(document).on('select2:open', () => {
    document.querySelector('.select2-search__field').focus();
  });

/*
    $(document).on('select2:open', (e) => {
        const selectId = e.target.id

        $(".select2-search__field[aria-controls='select2-" + selectId + "-results']").each(function (
            key,
            value,
        ) {
            value.focus()
        })
    });
*/

    /*//This is done serverside
        sorter: function(data) {
            return data.sort(function(a, b) {
                return a.text.toLowerCase() < b.text.toLowerCase() ? -1 : a.text.toLowerCase() > b.text.toLowerCase() ? 1 : 0;
            });
        }
    });
     */
});

/* Start embedded form fix */
document.querySelectorAll('.removechilds .removechilds').forEach(function(el) {
    el.className = "";
})
if(document.getElementsByClassName('a2lix_translations').length > 0) {
    document.getElementsByClassName('a2lix_translations')[0].parentNode.className = "";
}
/* End embedded form fix */

